import { 
   React, styled, Component, ContextYo, Fragment
} from '..'

const StyleWrapper = styled.div`
   background: black;
   color: white;
   padding-top: 5px;
   padding-bottom: 5px;
   padding-left: 10px;
   padding-right: 10px;
   button {
      margin-left: 10px;
      margin-right: 10px;
   }
   text-align: right;
`;

/*
 let SignUpForm = function ({handleInputChange, handleSignUp}){
   return (
      <Fragment>
         <input id="email" type="text" placeholder="Email" onChange={handleInputChange}/>
         <input id="password" type="text" placeholder="Password" onChange={handleInputChange}/>
         <button onClick={handleSignUp}>Sign Up</button>
      </Fragment>
   )
 }
 */

 let SignInForm = function ({handleInputChange, handleSignIn}){
   return (
      <Fragment>
         <input id="email" type="text" placeholder="Email" onChange={handleInputChange}/>
         <input id="password" type="password" placeholder="Password" onChange={handleInputChange}/>
         <button onClick={handleSignIn}>Sign In</button>
         <label style={{marginLeft:"10px;"}}>Dont have an account yet? Please contact us.</label>
      </Fragment>
   )
 }

 let SignOutButton = function ({handleSignOut}){
   return (
      <Fragment>
         <button onClick={handleSignOut}>Sign Out</button>
      </Fragment>
   )
 }

class AccountBasic extends Component {

   // For the contextYo
   static contextType = ContextYo
 
   constructor() {
      super();
      this.state = {};
      this.render = this.render.bind(this);
   };
 
   render() {
      let user = "Not Logged In"
      if(this.context && this.context.user && this.context.user.email) {
         user = this.context.user.email
      }
      return(
         <StyleWrapper>
            {user === "Not Logged In" ? <SignInForm {...this.context}/> : <Fragment><span>{user}</span><SignOutButton {...this.context}/></Fragment>}
         </StyleWrapper>
     )
   };
 };

 export default AccountBasic