/*
   TODO
   Get formik initial values from a doc in firebase conditionally
*/

/*
   TODO
   Find a way to move the folowing parts to another component.
      - maybe the logged in check in the componentDidMount
      - maybe handleInputChange but maybe not becuase formic handles this kind of stuff?
*/

/*
   TODO
   if touched show value as value otherwise show the value as a placeholder 
   maybe need to create a custom formik input type for this.
*/

/*
   TODO
   Figure out the formic features
*/

/*
   TODO
   Figure out how to use react-select in formic
   https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8#gistcomment-2887706
*/

/*
   TODO
   Yup for form validation
   https://jaredpalmer.com/formik/docs/guides/validation
*/

/*
   TODO 
   Figure out the custom input 

const CustomInputComponent = ({
   field, // { name, value, onChange, onBlur }
   form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
   ...props
 }) => (
   <div>
      <input type="text" {...field} {...props} />
      {touched[field.name] &&
         errors[field.name] && <div className="error">{errors[field.name]}</div>}
   </div>
);

*/

import { Field } from 'formik';
import { React } from '../index'

class FormYo extends React.Component {
   render() {
      return <p>FormYo</p>
   }
}

function SelectField ({options, name}) {
   return (
      <Field component="select" name={name}>
         {options.map((option, i) =>{
            const joinedOption = option.replace(/\s/g, '-');
            const flatOption = joinedOption.toLowerCase()
            return <option key={i} value={flatOption}>{option}</option> 
         })}
      </Field>
)}

function TextField ({ name }) {
   return (
      <Field name={name} type="text" />
)}

export default FormYo

export {
   TextField,
   SelectField
}