/*
   TODO
   The default is to use the firebase update method but this gives an erro if the doc is not
   there - it needs to use the set method when the doc does not already exist.
*/

/*
   TODO
   Write the doc part of formYo
   FormYo uses Formic to store values to firebase firestore on submit
*/



import { firebase } from '../index'

function dataYoLoad (objectForInitial, that){
   if (typeof window !== 'undefined') {

      let dataName = "dataname"
      let dataPath = "test"
      if(objectForInitial && objectForInitial.dataPath) {
         
         dataPath = objectForInitial.dataPath
         dataName = objectForInitial.dataPath

         if(objectForInitial.dataName) {
            dataName = objectForInitial.dataName
         }

      }

      if(objectForInitial && objectForInitial.retriveType ){

         if(objectForInitial.retriveType === "user"){

            firebase.auth.onAuthStateChanged(user =>{
               console.log("Index Auth State Changed")
               if (user) {
               let docID = user.email
               // console.log(docID)
               return firebase.db.collection(dataPath).doc(docID).onSnapshot(snap => {   
                  if(snap.data()) {
                        //console.log("snap.data()")
                        //console.log(snap.data())
                        that.setState({
                           [dataName]: snap.data()
                        });
                        console.log(" Get inititial that.state for " + dataName)
                        console.log(that.state[dataName])
                        return user
                  } 
                  else {
                     console.log("Error retriving from db")
                  }    
                  }, err => {
                        console.log(err.message);
                     })
                  }
               else {
                  console.log("No user")
               }
            })

         } else if (objectForInitial.retriveType === "doc") {

            if(objectForInitial.docID){
               return firebase.db.collection(dataPath).doc(objectForInitial.docID).onSnapshot(snap => {   
                  if(snap.data()) {
                        //console.log("snap.data()")
                        //console.log(snap.data())
                        that.setState({
                           [dataName]: snap.data()
                        });
                        console.log(" Get inititial that.state for " + dataName)
                        console.log(that.state[dataName])
                        return
                  } else{
                     console.log("Error retriving from db")
                  }    
               }, err => {
                     console.log(err.message);
               })

            }
            else {
               console.log("no docID Provided")
               return {error: "no docID Provided"}
            }

         } else {

            // #TODO
            // Implement collection initional result
            // set the option to limit the documents returned
            // Impliment the col retrive type to also be here 
            // when the used explicitly sets it to col and 
            // and when the user does not set it at all

         }

      } else {

         // #TODO
         // Implement collection initional result
         // set the option to limit the documents returned

         if (objectForInitial.limit){
            return firebase.db.collection(dataPath).where(objectForInitial.limit.key, objectForInitial.limit.test, objectForInitial.limit.value)
            .onSnapshot(snap => {   
               let dataToReturn = []
               snap.forEach(function(doc) {
                  dataToReturn.push(doc.data());
               });
               that.setState({
                  [dataName]: dataToReturn
               });
               console.log("Get inititial that.state for " + dataName + " with limit")
               console.log(objectForInitial.limit)
               console.log(that.state[dataName])
   
            }, err => {
                  console.log(err.message);
            })
         } else {

            return firebase.db.collection(dataPath).onSnapshot(snap => {   
               let dataToReturn = []
               snap.forEach(function(doc) {
                  dataToReturn.push(doc.data());
               });
               that.setState({
                  [dataName]: dataToReturn
               });
               console.log("Get inititial that.state for " + dataName)
               console.log(that.state[dataName])
   
            }, err => {
                  console.log(err.message);
            })

         }

      }

   }
}

let dataYoUpdate = function (objectForSave, that) {

   let proploopDeeperLevels = function (activeSub, activeSubPathName, valueForFirebaseUpdate) {
      for (var prop in activeSub) {
         let newPath = activeSubPathName + "." + prop
         if(typeof activeSub[prop] === 'string'){
            //console.log("activeSub = String VALUE");
            //console.log(activeSub);
            valueForFirebaseUpdate[newPath] = activeSub[prop]
            //console.log("Add to valueForFirebaseUpdate at path:" + newPath + " value:" + activeSub[prop]);
            //console.log(valueForFirebaseUpdate);
         } else{
            proploopDeeperLevels(activeSub[prop], newPath, valueForFirebaseUpdate)
         }
      }
   }

   let proploop = function (activeSub, valueForFirebaseUpdate) {
      for (var prop in activeSub) {
         
         /*
         let propToTest = prop.toString();
         console.log(propToTest)
         let regex = new RegExp("/\./g");
         console.log("propToTest.indexOf('.')")
         console.log(regex.test(propToTest))
         */

         if(typeof activeSub[prop] === 'string'){
            //console.log("activeSub = String VALUE");
            //console.log(activeSub);
            valueForFirebaseUpdate[prop] = activeSub[prop]
            
         } else{
            proploopDeeperLevels(activeSub[prop], prop, valueForFirebaseUpdate)
         }
      }
      return valueForFirebaseUpdate
   }

   function getValuesForFirebaseUpdate (values){
      let valueForFirebaseUpdate = {}
      console.log("PROPLOOP")
      return proploop(values, valueForFirebaseUpdate)
   }

   let dataPath = "test"
   if (objectForSave && objectForSave.dataPath) {
      dataPath = objectForSave.dataPath
   }

   function firebaseUpdateDoc (valuesForFirebase){
      console.log(valuesForFirebase);
      firebase.db.collection(dataPath).doc(objectForSave.docID).update(
         valuesForFirebase
      ).then(()=>{
         console.log("DB updated: Document added or updated")
         if (typeof window !== 'undefined') {
         //   window.location.reload();
         };
      })
   }

   function firebaseAddToCollection (valuesForFirebase){
      console.log(valuesForFirebase);
      firebase.db.collection(dataPath).add(
         valuesForFirebase
      ).then(()=>{
         console.log("DB updated: Added to collection")
         if (typeof window !== 'undefined') {
            //window.location.reload();
         };
      })
   }

   // 
   

   if (objectForSave && objectForSave.saveType === "doc") {

      if(typeof objectForSave.docID !== 'string'){
         objectForSave.docID = new Date().valueOf().toString();
      }
      // that.context.user.email
      firebaseUpdateDoc(getValuesForFirebaseUpdate(objectForSave.values))
   }

   else if (objectForSave && objectForSave.saveType === "col") {
      // this still might need work
      firebaseAddToCollection(getValuesForFirebaseUpdate(objectForSave.values))
   }

   else {
      // this still might need work
      firebaseAddToCollection(getValuesForFirebaseUpdate(objectForSave.values))
   }
}

export {
   dataYoLoad,
   dataYoUpdate
}