import React from "react"
import { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

/*

/*

/// gatsby-config.js file

module.exports = {
  siteMetadata: {
    title: `ShipitYo Starter`,
    description: `This is the description`,
    baseurl: 'localhost:8000',
    author: "ShipitYo"
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `imageyo`,
        path: `${__dirname}/src/imageyo`,
      }
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
   
  ],
}


/// NB NB


The images needs to be in the ${__dirname}/src/imageyo folder 

/// Sample test page imageyo-test.js file


import { React, Component, SEO, styled, Link, ImageYo } from "../shipityo/index";
import Layout from "../shipityo/components/layout";

const StyleWrapper = styled.div`
   max-width: 1000px;
   margin-bottom: 1.45rem;
   #font{
      font-family: 'Roboto';
   }
`;

const ImageBox = styled.div`
  background: blue;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  div{
    overflow: hidden
  }

  .gatsby-image-wrapper {
  }
  
  #beer1{
    background: pink;
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
  }
  #beer2{
    background: orange;
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
  }

`;

class Page extends Component {

  constructor({data}) {
    super();
    this.state = {
    };
    this.render = this.render.bind(this);
  };

  render() {
    return (
      <Layout>
        <SEO title="Image Test" keywords={[`shipityo`]} />        
        <StyleWrapper>
          <ImageBox>
            <div id="beer1"><ImageYo src="beer1.jpg"/></div>
            <div id="beer2"><ImageYo src="beer2.jpg"/></div>
            <div id="beer3"><ImageYo src="beer3.jpg"/></div>
            <div id="beer4"><ImageYo src="beer4.jpg"/></div>
          </ImageBox>
        </StyleWrapper>
        <Link to="/">Go back home</Link>
      </Layout>
    );
  };
};

export default Page;



*/


class ImageComponent extends Component {

  
  render() {

    let images = {}
    //console.log("this.props.data.allFile.edges")
    //console.log(this.props.data.allFile.edges)
    //console.log("images")
    //console.log(images)

    this.props.data.allFile.edges.forEach(element => {
      images[element.node.relativePath] = element.node.childImageSharp.sizes;
      //console.log(images)
    });

    // Path if the sourceInstanceName is used
    let pathToUse = `${this.props.src}`
    // Path if the sourceInstanceName is not used
    //let pathToUse = `imageyo/${this.props.src}`

    //console.log("pathToUse")
    //console.log(pathToUse)

    return (
      <Img sizes={images[pathToUse]} />
    );
  }

}

// <Img sizes={images[this.props.src]} />
//

export default props => (
  <StaticQuery
    query={graphql`
        query imageyo {
          allFile(filter: {sourceInstanceName:{eq:"imageyo"}}) {
              edges {
                node {
                    name
                    relativePath
                    prettySize
                    extension
                    birthTime
                    publicURL
                    childImageSharp {
                      sizes(maxWidth: 1600) {
                      ...GatsbyImageSharpSizes_tracedSVG
                      }
                    }
                }
              }
          }
        }
    `}
    render={data => <ImageComponent data={data} {...props}/>}
  />
)

// allFile(filter: {relativeDirectory: {regex: "/imageyo/"}}) {
// allFile(filter: {sourceInstanceName:{eq:"imageyo"}}) {

/*

Rebuild this to process all images in a folder and serve the image the user is developer is requesting in a file.
filename is relative to "/src/media/images/imageyo/"
<Imageyo src="/banners/banner01.png" botname="Banner 1"/>

The image always has to be in a div and has to fill the div that contains it. 
Unless there is some technical reason this does not work.

<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
   <Imageyo src="/banners/banner01.png" alt="Banner 1"/>
</div>

The staticQuery needs to load all the images in the folder using the 
-- shipityo
-- shipityo-custom

The exports from shipityo checks if there is a custom version of a file and 
if so it uses that version if no custom version then it uses the default version

Shipityo has an install function similar to nodeengine that places all the default files in the right places.


In the user file there is 2 imports the imangeYo index builder and then the imageYo image placer. 
The builder returns the index of where the images are in the array and the placer places that image.
But perhaps the builder should return the actual image info too otherwise 
if there are many images on a page the request to get the image array might run many times.

*/